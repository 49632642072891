.progress-bar {
    height: 4px;
    background-color: rgba(5, 114, 206, 0.2);
    width: 250px;
    overflow: hidden;
  }
  
  .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: #0592E0;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0 50%;
  }
  
  @keyframes indeterminateAnimation {
    0% {
      transform:  translateX(0) scaleX(0);
    }
    40% {
      transform:  translateX(0) scaleX(0.4);
    }
    100% {
      transform:  translateX(100%) scaleX(0.5);
    }
  }