.main-content {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .container-admin {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;

  }

  // Navbar
  .navbar-top {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
    z-index: 1;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar-landing {
    width: 99vw;
    height: 9vh;
    opacity: 0.78;
    background: rgba(24, 152, 223, 0.41);
    box-shadow: 0px 16px 15px 0px rgba(255, 255, 255, 0.25) inset, 0px -20px 18px 0px rgba(51, 92, 129, 0.21) inset, 0px 17px 17px 0px rgba(51, 92, 129, 0.30);
    backdrop-filter: blur(10.498839378356934px);
    padding-left: 11.5vw;
    padding-right: 11.5vw;
  }

  .landing-bg-image {
    position: absolute;
    background: url("../../../img/theme/landing-background.jpg") lightgray 50% / cover no-repeat;
    background-size: cover;
    z-index: -1;
    width: 100%;
    height: 100vh;
  }

  .landing-bg-image::before {
    content: "";
    position: absolute;
    background: #0593e0dc;
    z-index: -1;
    width: 100%;
    height: 100vh;
  }

  .container-content {
    display: grid;
    place-items: center;
    align-content: space-between;
  }

  .nav-landing-button {
    width: 8vw;
    height: 5.5vh;
    flex-shrink: 0;
    border-radius: 2vw;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.26) 0%, rgba(255, 255, 255, 0.07) 100%);
    box-shadow: 0px 4.953846454620361px 5.661538600921631px 0px rgba(51, 92, 129, 0.30), 0px 6.3692307472229px 7.076923370361328px 0px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(7.076923370361328px);
    border: none;
    padding: 0;

    &:hover {
      color: #FFF;
    } 

    font-size: 1.85vh;
    color: #FFF;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x + calc($grid-gutter-width / 2)) !important;
      padding-right: ($main-content-padding-x + calc($grid-gutter-width / 2)) !important;
    }
  }
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-left: 11.5vw;
  padding-right: 11.5vw;

  .landing-card {
    height: 220px;
    max-width: 1087px;
    flex-shrink: 0;
    border-radius: 0px 101.988px;
    background: rgba(5, 146, 224, 0.09);
    box-shadow: 0px 9.68338680267334px 9.68338680267334px 0px rgba(51, 92, 129, 0.30), 0px 9.113775253295898px 8.544163703918457px 0px rgba(255, 255, 255, 0.25) inset, 0px -11.392219543457031px 10.252997398376465px 0px rgba(51, 92, 129, 0.21) inset;
    backdrop-filter: blur(5.980254173278809px);

    .landing-card-body {
      margin: 30px 40px 30px 40px;
      height: 150px;
      flex-shrink: 0;
      border-radius: 0px 101.988px;
      background: rgba(51, 92, 129, 0.65);
      box-shadow: 0px 9.68338680267334px 9.68338680267334px 0px rgba(51, 92, 129, 0.30), 0px 9.113775253295898px 8.544163703918457px 0px rgba(255, 255, 255, 0.25) inset, 0px -11.392219543457031px 10.252997398376465px 0px rgba(51, 92, 129, 0.21) inset;
      backdrop-filter: blur(5.980254173278809px);
      border: 1px solid rgba(255, 255, 255, 0.18);
    }
  }

  .section {
    height: 7.6804915514593vh;
    width: 99vw;
    flex-shrink: 0;
    background: rgba(24, 152, 223, 0.67);
    box-shadow: 0px 16px 15px 0px rgba(255, 255, 255, 0.25) inset, 0px -20px 18px 0px rgba(51, 92, 129, 0.21) inset, 0px 17px 17px 0px rgba(51, 92, 129, 0.30);
    backdrop-filter: blur(10.498839378356934px);
  }

  .section-card {
    fill: #FFF;
    box-shadow: 0px 16px 15px 0px rgba(255, 255, 255, 0.25) inset, 0px -20px 18px 0px rgba(51, 92, 129, 0.21) inset;
    filter: drop-shadow(0px 17px 17px rgba(51, 92, 129, 0.30));
    backdrop-filter: blur(10.498839378356934px);
    height: 7vh;
    border-radius: 3vh;
    width: 100%;
  }

  .section-card-offer {
    fill: #FFF;
    box-shadow: 0px 16px 15px 0px rgba(255, 255, 255, 0.25) inset, 0px -20px 18px 0px rgba(51, 92, 129, 0.21) inset;
    filter: drop-shadow(0px 17px 17px rgba(51, 92, 129, 0.30));
    backdrop-filter: blur(10.498839378356934px);
    height: 25vh;
    border-radius: 5vh;
    width: 100%;
  }
  
  .message {
    width: 70vw;
    color: #FFF;
    font-family: Roboto;
    font-size: 1.65vw;
    font-style: normal;
    font-weight: 300;
    line-height: 4vh;

    span {
      font-weight: 700;
    }
  }

  .suscribe-button {
    display: flex;
    padding: 1vh 7vw 1vh 7vw;
    align-items: center;
    border-radius: 40.079px;
    background: #FFF;
    box-shadow: 0px 11.222223281860352px 12.825397491455078px 0px rgba(51, 92, 129, 0.30), 0px 14.428570747375488px 16.03174591064453px 0px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(16.03174591064453px);
  }

  .landing-card-text {
    width: 10vw;
    height: 12vh;
    flex-shrink: 0;
    background: #0592E0;
    border-radius: 0px 5vh;
  }
}

.first-content {
  justify-content: space-between;
  padding-bottom: 6vh;

  .title-image {
    max-width: 38.09vw;
    height: 16vh;
    margin-top: 7vh;
  }
}

.second-content {
  .landing-card-info {
    width: 100%;
    padding: 2vh;
    height: 6,65vw;
    border-radius: 5.5300vh;
    background: #0592E0;
    box-shadow: 0px 1.5vh 1.4vh 0px rgba(255, 255, 255, 0.25) inset, 0px -1.6vh 1.5vh 0px rgba(51, 92, 129, 0.21) inset, 0px 1.4vh 1.4vh 0px rgba(51, 92, 129, 0.30);
    backdrop-filter: blur(0.77vh);

    .landing-card-body-info {
      height: 4.5vw;
      border-radius: 3.6866vh;
      border: 2px solid rgba(255, 255, 255, 0.30);
      background: none;

      span {
        width: 42vw;
        color: #FFF;
        font-family: Roboto;
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        line-height:3vh;
        text-align: center;
      }
    }
  }

  .landing-card-features {
    margin-right: 6.47vw;
    height: 65.05vh;
    width: 70vw;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-color: #FFF;
    border-radius: 0px 15vh;

    .landing-card-body-features {
      display: flex
    }

    .list {
      min-height: 0.5vh;
      padding-left: 1vh;

      svg {
        height: 5vh;
        width: 5vh;
        margin-right: 5vw;
      }
    }

    .content-info {
      width: 70vw;
    }

    .info-section {
      width: 70vw;
      height: 57vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;

      img {
        max-width: 35vw;
        height: auto;
      }
    }

    .hero-section {
      padding-left: 5vw;
      display: flex;
      align-items: center;

      img {
        height: 55vh;
      }
    }
  }

}

.landing-button {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-right: 0;

  svg {
    stroke: notiexpress-color("primary-default") !important;
  }

  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    svg {
      stroke: notiexpress-color("secondary-default") !important;
    }
  }

  &:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    svg {
      stroke: notiexpress-color("secondary-dark-1") !important;
    }
  }
}

.divider {
  height: 2px;
  align-self: stretch;
  background: rgba(51, 92, 129, 0.51);
  margin: 0;
}

.main-content-mobile {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .container-admin {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {

  @each $breakpoint,
  $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {

        // Left
        &.fixed-left+.main-content {
          margin-left: 70px;
        }

        // Right
        &.fixed-right+.main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

.ov-x-auto {
  //overflow-x: auto;
}

.btn-group {
  flex-wrap: wrap;
}