//
// Pagination
//


.page-item {
  &:hover:not(.active):not(:disabled):not(:active) {
    .page-link {
      border: 2px solid notiexpress-color("secondary-default");
      color: notiexpress-color("secondary-default");
      background-color: notiexpress-color("neutro-1");
    }
  }

  &:active {
    .page-link {
      border: 2px solid notiexpress-color("secondary-dark-2");
      color: notiexpress-color("secondary-dark-2");
      background-color: notiexpress-color("neutro-1");
    }
  }

  &.active .page-link {
    box-shadow: $pagination-active-box-shadow;
  }

  .page-link {
    color: notiexpress-color("primary-default");
  }

  .page-link,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 3px;
    border-radius: 30% !important;
    border-color: notiexpress-color("primary-variant-3");
    border-width: 3px;
    width: 36px;
    height: 36px;
    font-size: $font-size-sm;
  }
}


// Size variations

.pagination-lg {
  .page-item {

    .page-link,
    span {
      width: 46px;
      height: 46px;
      line-height: 46px;
    }
  }
}

.pagination-sm {
  .page-item {

    .page-link,
    span {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
}