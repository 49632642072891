
/* width */
::-webkit-scrollbar {
    width: 12px;
}
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    background: #A3CBE0;
    border-radius: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #335C81;
    border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #274060;
}