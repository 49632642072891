.table-responsive {
    min-height: 150px;
}

.noDataMessage {
    position: absolute;
    top: 40px;
}

.text-center-tr {
    text-align: left !important;
}
.text-right-tr {
    text-align: left !important;
}

