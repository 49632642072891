//
// Table
//

// General styles

.table {
  thead th {
    padding-top: $table-head-spacer-y;
    padding-bottom: $table-head-spacer-y;
    font-size: $table-head-font-size;
    text-transform: $table-head-text-transform;
    letter-spacing: $table-head-letter-spacing;
    border-bottom: $table-border-width solid $table-border-color;
  }

  th {
    font-weight: $table-head-font-weight;
  }

  td {
    .progress {
      height: 3px;
      width: 120px;
      margin: 0;
    }
  }

  td,
  th {
    font-size: $table-body-font-size;
    white-space: nowrap;
  }

  // Vetical align table content

  &.align-items-center {

    td,
    th {
      vertical-align: middle;
    }
  }

  // Styles for dark table

  .thead-dark {
    th {
      background-color: notiexpress-color("primart-variant-4") !important;
      color: notiexpress-color("secondary-default") !important;
    }
  }

  // Styles for light table

  .thead-light {
    th {
      background-color: notiexpress-color("primart-variant-4") !important;
      color: notiexpress-color("secondary-default") !important;
      padding: 0.5rem 0.5rem;
      border-radius: 6px;
      border: 2px solid notiexpress-color("neutro-1") !important;
    }
  }

  @include media-breakpoint-down(lg) {
    .hide-column {
      display: none;
    }
  }
}

// Add transition for hover state

.table-hover {
  tr {
    @include transition($transition-base);
  }
}

// Flush tables

.table-flush {

  td,
  th {
    border-left: 0;
    border-right: 0;
  }

  tbody {
    tr {
      &:first-child {

        td,
        th {
          border-top: 0;
        }
      }

      &:last-child {

        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}

// Tables inside cards

.card {
  .table {
    margin-bottom: 0;

    td,
    th {
      padding-left: $table-cell-padding-sm;
      padding-right: $table-cell-padding-sm;
    }
  }
}

.providence-row {
  &:hover {
    background-color: notiexpress-color("primary-default") !important;

    span {
      color: notiexpress-color("neutro-1") !important;
    }

    svg {
      stroke: notiexpress-color("neutro-1") !important;
    }
  }

}