// Weight and italics

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}


// Text decorations

.text-underline {
  text-decoration: underline;
}

.text-through {
  text-decoration: line-through;
}


// Text size

.text-xs {
  font-size: $font-size-xs !important;
}

.text-sm {
  font-size: $font-size-sm !important;
}

.text-lg {
  font-size: $font-size-lg !important;
}

.text-xl {
  font-size: $font-size-xl !important;
}


// Line heights

.lh-100 {
  line-height: 1;
}

.lh-110 {
  line-height: 1.1;
}

.lh-120 {
  line-height: 1.2;
}

.lh-130 {
  line-height: 1.3;
}

.lh-140 {
  line-height: 1.4;
}

.lh-150 {
  line-height: 1.5;
}

.lh-160 {
  line-height: 1.6;
}

.lh-170 {
  line-height: 1.7;
}

.lh-180 {
  line-height: 1.8;
}


// Letter spacings

.ls-1 {
  letter-spacing: .0625rem;
}

.ls-15 {
  letter-spacing: .09375rem;
}

.ls-2 {
  letter-spacing: 0.125rem;
}

// Color variations

@each $color,
$value in $colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

// Notiexpress colors

@each $color,
$value in $notiexpress-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);

  .text-#{$color} {
    color: $value;
  }
}

// Notiexpress fonts

.text-heading-1 {
  font-family: 'Baloo 2', sans-serif;
  font-weight: bold;
  color: notiexpress-color("primary-default");
  font-size: 32px;
  padding-bottom: 1rem;
}

.text-heading-2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: $secondary-default;
  font-size: 24px;
}

.text-heading-3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: $secondary-dark-2;
  font-size: 1.3vw;

  &.pre {
    white-space: pre-line;
  }
}

.text-heading-4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: $secondary-dark-2;
  font-size: 16px;
}

.text-heading-landing-1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 4.7821812596006vh;
  line-height: 4.2804915514593vh;
  font-style: normal;
}

.text-heading-landing-2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 3.3293394777266vh;
  line-height: 4.2804915514593vh
}

.text-heading-landing-2-small {
  color: #FFF;
  font-family: Roboto;
  font-size: 2.2072196620584vh;
  font-style: normal;
  font-weight: 700;
  line-height: 3.3149001536098vh;
  letter-spacing: 0.04938rem;
}

.text-heading-landing-3 {
  color: #FFF;
  font-family: 'Baloo 2', sans-serif;
  font-size: 5.5300vh;
  font-style: normal;
  font-weight: bold;
}

.text-button {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: $neutro-1;
  font-size: 16px;
}

.text-dark-link-regular {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.text-link-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: $noti-info;
  font-size: 16px;

  &:hover {
    color: $noti-info;
  }
}

.text-link-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: $noti-info;
  font-size: 14px;

  &:hover {
    color: $noti-info;
  }
}

.text-link-large {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: $noti-info;

  &:hover {
    color: $noti-info;
  }
}

.text-body-small {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: notiexpress-color("neutro-2");
}

.text-body-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: $neutro-2;
  font-size: 12px;
}

.slogan-text {
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 4vh;
  font-style: normal;
  font-weight: 500;
  line-height: 10vh;
  opacity: 0.69;
}

.slogan-text-dark {
  color: #FFF;
  font-family: Roboto;
  font-size: 1.12vw;
  font-style: normal;
  font-weight: 400;
}

.text-body-large {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: $neutro-2;
  font-size: 14px;
}

.text-caps-regular {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.text-body-large-bold {
  font-family: 'Roboto', sans-serif;
  color: $neutro-2;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.text-exp-caducity {
  font-family: 'Roboto', sans-serif;
  color: $neutro-2;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  ;
}

.text-body-regular-bold {
  font-family: 'Roboto', sans-serif;
  color: $neutro-2;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.text-heading-3-blue {
  color: $primary-default;
  text-align: center;
  font-family: Roboto;
  font-size: 75px;
  font-style: normal;
  font-weight: 800;
}

.text-heading-3-blue-sm {
  color: $primary-default;
  font-family: Roboto;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;

}

.text-heading-1-secondary {
  font-family: 'Baloo 2', sans-serif;
  font-weight: bold;
  color: notiexpress-color("secondary-default");
  font-size: 3vw;
  padding-bottom: 1rem;
}

.text-heading-3-secondary {
  color: $secondary-default;
  text-align: center;
  font-family: Roboto;
  font-size: 2.46vh;
  font-style: normal;
  font-weight: 700;
  line-height: 4.61vh;
  /* 100% */
}